<template>
    <div>
        <div class="container">
            <section class="pt-3  sm-pt-50px position-relative">
                <div class="row justify-content-center mb-5 sm-mb-30px">
                    <div class="col-xl-6 col-lg-8 col-md-10 text-center">
                        <h3 class="text-dark mb-0 fw-600">{{ main_title }}</h3>
                    </div>
                </div>
                <div class="container">
                    <div class="row row-cols-1 row-cols-lg-2 row-cols-sm-2 justify-content-center">
                        <div class="col icon-with-text-style-04 transition-inner-all md-mb-30px pb-3"
                            v-for="(section, index) in sections.slice(1)" :key="index">
                            <div
                                class="feature-box  transition dark-hover bg-white border-radius-6px pt-5 pb-10 ps-15 pe-15 lg-ps-8 lg-pe-8 last-paragraph-no-margin box-shadow-quadruple-large box-shadow-hover overflow-hidden">
                                <div class="feature-box-icon">
                                    <img :src="section.image" alt="" style="height: 40px;">
                                </div>
                                <div class="feature-box-content">
                                    <span class="d-inline-block text-dark-gray fw-600 fs-17 mb-5px">{{ section.title
                                        }}</span>
                                    <div v-for="(item, index) in section.content.split('•')" :key="index">
                                        <p class="content_sm">{{ item }}</p>
                                    </div>
                                </div>
                                <div class="feature-box-overlay "></div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt-3">
                        <button class="btn btn-gradient-purple-pink btn-rounded btn-large">View All </button>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const sections = ref([]);
let main_title = ref('Loading...');
let titles = ref([]);
let serviceSections = ref([]);
let serviceImages = ref([]);


const fetchData = async () => {
    const page = 'WhyUsPage';

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}`);
        const pageData = response.data;

        if (!pageData || !pageData.sections) {
            throw new Error('Invalid response data');
        }

        const filteredSections = pageData.sections.slice(0, 3);
        sections.value = filteredSections.map(section => ({
            title: section.title || 'Default Title',
            content: section.content || 'Default content.',
            image: (section.images && section.images.length > 0) ? section.images[0].url : 'content/surveyor/images/default_image.jpg'
        }));

        console.log('Data fetched for sections:', sections.value);
        titles.value = sections.value.map(section => section.title);
        serviceSections.value = sections.value.map(section => section.content);
        serviceImages.value = sections.value.map(section => section.image);

        main_title.value = sections.value[0]?.title || 'Default Title';

    } catch (error) {
        console.error('Error fetching data:', error);
        sections.value = [{
            title: 'Error',
            content: 'Error loading content.',
            image: 'content/surveyor/images/default_image.jpg'
        }];
    }
};

onMounted(fetchData);
</script>
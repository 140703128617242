<template>
    <div>
        <section class="position-relative z-index-1 background-position-left-top background-no-repeat overflow-hidden"
            style="background-image: url('images/demo-elearning-02.png')">
            <div class="row justify-content-center mb-3">
                <div class="col-xl-8 col-lg-9 col-md-10 text-center">
                    <h3 class="text-dark-gray fw-700 ls-minus-1px">{{ main_title }}
                    </h3>
                    <span class="d-block fs-17 text-dark fw-500 mb-5px">{{ secondSectionContent }}</span>
                </div>
            </div>
            <div class="position-absolute right-0px bottom-minus-90px z-index-minus-1 d-none d-md-inline-block"
                data-bottom-top="transform: translateY(-50px)" data-top-bottom="transform: translateY(50px)">
                <img src="images/demo-elearning-04.png" alt="">
            </div>
            <div class="container">
                <div class="row g-0 row-cols-1 row-cols-lg-4 row-cols-sm-2 g-0 align-items-center mb-3">
                    <!-- start features box item -->
                    <div class="pt-2 col services-box-style-07 text-center last-paragraph-no-margin border border-radius border-color-transparent-light md-mb-50px xs-border-end-0"
                        v-for="(section, index) in sections.slice(1)" :key="index">
                        <a href="/services" class="service-link">
                            <div class="pe-20px ps-20px pb-20px xl-ps-30px xl-pe-30px">
                                <div class="position-relative ms-auto me-auto mb-25px">
                                    <img :src="section.image" class="h-75px position-relative z-index-1 mt-35px" alt="">
                                    <div
                                        class="h-100px w-100px rounded-circle bg-very-light-gray position-absolute top-0 start-50 translate-middle-x">
                                    </div>
                                </div>
                                <span class="single-line-title fs-18 fw-600 text-dark-gray d-block mb-5px"
                                    style="display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;">{{
                                    section.title }}</span>
                                <p class="lh-30"
                                    style="display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;">
                                    {{ (index === 8 || index === 11) ? trimText(section.content, 15) :
                                    trimText(section.content, 12) }}</p>
                            </div>
                            <div
                                class="d-flex flex-column overflow-hidden w-100 justify-content-center position-relative">
                                <div
                                    class="btn-primary d-flex align-items-center justify-content-center bg-dark-gray border-radius-bottom">
                                    <span
                                        class="inner-link btn btn-link btn-hover-animation-switch btn-extra-large text-white"
                                        style="font-size: 15px; padding: 15px 15px;">
                                        <span>
                                            <span class="btn-text">More details</span>
                                            <span class="btn-icon"><i
                                                    class="feather icon-feather-arrow-right"></i></span>
                                            <span class="btn-icon"><i
                                                    class="feather icon-feather-arrow-right"></i></span>
                                            <span class="dots"><span>.</span><span>.</span><span>.</span></span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const sections = ref([]);
let main_title = ref('Loading...');
let titles = ref([]);
let serviceSections = ref([]);
let serviceImages = ref([]);
const trimText = (text, wordLimit) => {
    if (!text) return ''; // Return empty string if text is undefined
    const words = text.split(' ');
    if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
};

const fetchData = async () => {
    const page = 'ServicesPage';
    const sectionIDs = [
        "665d5f639ac784c296cbd941",
        "665d5f7a9ac784c296cbd947",
        "665d5f979ac784c296cbd959",
        "665d5fba9ac784c296cbd973",
        "665d5fdc9ac784c296cbd995",
        "665d602b9ac784c296cbd9d0",
        "665d60499ac784c296cbda1e",
        "665d605c9ac784c296cbda5c",
        "665d607c9ac784c296cbdaa2",
        "665d60949ac784c296cbdaf0",
        "665d60b29ac784c296cbdb46",
        "665d60d59ac784c296cbdba4",
        "665d60fc9ac784c296cbdc0a",
        "665d61149ac784c296cbdc78",
        "665d61859ac784c296cbdcee"
    ];

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}`);
        const pageData = response.data;
        const filteredSections = pageData.sections.filter(section => sectionIDs.includes(section._id));

        sections.value = filteredSections.map(section => ({
            title: section.title || 'Default Title',
            content: section.content || 'Default content.',
            image: (section.images && section.images.length > 0) ? section.images[0].url : 'content/surveyor/images/default_image.jpg'
        }));

        titles.value = filteredSections.map(section => section.title);
        serviceSections.value = filteredSections.map(section => section.content);
        serviceImages.value = filteredSections.map(section => section.images[0].url);

        main_title.value = filteredSections[0].title;

    } catch (error) {
        console.error('Error fetching data:', error);
        sections.value = [{
            title: 'Error',
            content: 'Error loading content.',
            image: 'content/surveyor/images/default_image.jpg'
        }];
    }
};



onMounted(fetchData);
</script>

<style>
.dots span {
    animation: dot 1s infinite;
}

.dots span:nth-child(2) {
    animation-delay: 0.2s;
}

.dots span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes dot {

    0%,
    20%,
    80%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

/* CSS */
.single-line-title {
    white-space: nowrap;
    /* Prevents the text from wrapping to a new line */
    overflow: hidden;
    /* Ensures overflow text is hidden */
    text-overflow: ellipsis;
    /* Adds an ellipsis to indicate text has been truncated */
    max-width: 100%;
    /* Optional: Adjusts the width to fit the container */
}

.border-radius {
    border-radius: 10px;
}

.border-radius-bottom {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
</style>
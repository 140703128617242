<template>
    <div>
        <header>
            <!-- start navigation -->
            <nav class="navbar navbar-expand-lg header-transparent bg-transparent header-reverse"
                data-header-hover="light">
                <div class="container-fluid">
                    <div class="col-auto col-xxl-3 col-lg-2 me-lg-0 me-auto">
                        <a class="navbar-brand" href="/">
                            <img src="https://back.smsfacilities.in/uploads/logo.png"
                                data-at2x="https://back.smsfacilities.in/uploads/logo.png" alt="" class="default-logo">
                            <img src="https://back.smsfacilities.in/uploads/logo.png"
                                data-at2x="https://back.smsfacilities.in/uploads/logo.png" alt="" class="alt-logo">
                            <img src="https://back.smsfacilities.in/uploads/logo.png"
                                data-at2x="images/demo-business-logo-black@2x.png" alt="" class="mobile-logo">
                        </a>
                    </div>
                    <div class="col-auto menu-order position-static">
                        <button class="navbar-toggler float-start" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                            @click="toggleMenu">
                            <span class="navbar-toggler-line"></span>
                            <span class="navbar-toggler-line"></span>
                            <span class="navbar-toggler-line"></span>
                            <span class="navbar-toggler-line"></span>
                        </button>
                        <div class="collapse navbar-collapse" :class="{ show: isMenuOpen }" id="navbarNav">
                            <ul class="navbar-nav alt-font">
                                <li class="nav-item"><a href="/" class="nav-link">Home</a></li>
                                <li class="nav-item dropdown" @mouseover="showDropdown" @mouseleave="hideDropdown">
                                    <a href="/services" class="nav-link dropdown-toggle" id="servicesDropdown"
                                        role="button" data-bs-toggle="dropdown" aria-expanded="false">Services</a>
                                    <ul class="dropdown-menu" aria-labelledby="servicesDropdown" style="margin: -46px;">
                                        <li v-for="title in titles.slice(0, 4)" :key="title"><a class="dropdown-item"
                                                href="/services">{{ title }}</a></li>
                                        <li v-if="titles.length > 4"><a class="dropdown-item" href="/services">More...</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a href="/apps" class="nav-link">Apps</a>
                                </li>
                                <li class="nav-item"><a href="/why-us" class="nav-link">Why Us</a>
                                </li>
                                <li class="nav-item"><a href="/join-us" class="nav-link">Join Us</a>
                                </li>
                                <li class="nav-item"><a href="/testimonials" class="nav-link">Testimonials</a></li>
                                <li class="nav-item"><a href="/contact" class="nav-link">Contact</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-auto col-xxl-3 col-lg-2 text-end d-none d-sm-flex">
                        <div class="header-icon">

                            <div class="header-button"><a href="/contact"
                                    class="btn btn-very-small btn-transparent-white-light btn-rounded">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    </div>
</template>


<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';

export default {
    name: 'HeaderComponent',
    setup() {
        const titles = ref([]);
        const baseUrl = process.env.VUE_APP_API_BASE_URL;
        const isMenuOpen = ref(false);
        console.log("baseUrl", baseUrl);
        const fetchTitles = async () => {
            try {
                const response = await axios.get(`${baseUrl}/pages/services/titles`);
                titles.value = response.data.slice(1); // Exclude the first title
            } catch (error) {
                console.error('Error fetching titles:', error);
            }
        };

        const showDropdown = (event) => {
            const dropdownMenu = event.currentTarget.querySelector('.dropdown-menu');
            dropdownMenu.classList.add('show');
        };

        const hideDropdown = (event) => {
            const dropdownMenu = event.currentTarget.querySelector('.dropdown-menu');
            dropdownMenu.classList.remove('show');
        };

        const toggleMenu = () => {
            isMenuOpen.value = !isMenuOpen.value;
        };

        onMounted(() => {
            fetchTitles();
        });

        return {
            titles,
            showDropdown,
            hideDropdown,
            isMenuOpen,
            toggleMenu
        };
    }
};
</script>

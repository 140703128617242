<template>

    <div>
        <section class="pt-3 sm-pt-50px">
            <div class="container">

                <div class="row mb-10 align-items-center">
                    <div class="col-lg-5 position-relative md-mb-20">
                        <div class="w-70 xs-w-80" data-animation-delay="50" data-shadow-animation="true">
                            <img :src="FirstSectionImage" alt="" class="border-radius-8px w-100">
                        </div>
                        <div class="w-60 overflow-hidden position-absolute right-minus-15px xs-right-15px xs-w-60 bottom-minus-50px"
                            data-shadow-animation="true" data-animation-delay="50"
                            data-bottom-top="transform: translateY(50px)"
                            data-top-bottom="transform: translateY(-50px)">
                            <img :src="secondSectionImage" alt=""
                                class="border-radius-8px w-100 box-shadow-quadruple-large" />
                        </div>
                    </div>
                    <div class="col-xl-5 col-lg-6 offset-lg-1">
                        <p class="mb-40px sm-mb-25px">{{ FirstSectionContent }}</p>
                        <p class="mb-40px sm-mb-25px">{{ secondSectionContent }}</p>
                    </div>
                </div>

            </div>
        </section>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const titleSection = ref('Loading...');
const FirstSectionContent = ref('Loading...');
const FirstSectionImage = ref('');

const secondSectionContent = ref('Loading...');
const secondSectionImage = ref('');


const fetchData = async () => {
    const page = 'HomePage';
    console.log('Fetching data for second section...');
    const sectionID = '665849501b769ba8c36b959d';

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}/sections/${sectionID}`);
        const section = response.data;
        console.log('Data fetched for second section:', section);
        titleSection.value = section.title;
        console.log("hello" + section);

        if (section && section.images && section.images.length > 0) {
            FirstSectionImage.value = section.images[0].url;
        }
        FirstSectionContent.value = section.content;
    } catch (error) {
        console.error(error);
        FirstSectionImage.value = 'default_image_url'; // replace with your default image url
        FirstSectionContent.value = 'Error loading content';
    }
};

const fetchDataSecond = async () => {
    const page = 'HomePage';
    console.log('Fetching data for second section...');
    const sectionID = '665849501b769ba8c36b959f';

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}/sections/${sectionID}`);
        const section = response.data;
        console.log('Data fetched for second section:', section);
        titleSection.value = section.title;
        console.log("hello" + section);

        if (section && section.images && section.images.length > 0) {
            secondSectionImage.value = section.images[0].url;
        }
        secondSectionContent.value = section.content;
    } catch (error) {
        console.error(error);
        secondSectionImage.value = 'default_image_url'; // replace with your default image url
        secondSectionContent.value = 'Error loading content';
    }
};

onMounted(() => {
    fetchData();
    fetchDataSecond();
});
</script>

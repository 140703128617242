<template>
    <div>
        <section class="pt-3 sm-pt-50px">
            <div class="container">
                <div class="row row-cols-1 row-cols-lg-2 row-cols-md-2 justify-content-center">
                    <!-- start features box item -->
                    <div class="col icon-with-text-style-01 md-mb-30px">
                        <div class="feature-box feature-box-center last-paragraph-no-margin text-center">
                            <div class="feature-box-icon mb-3">
                                <img :src="secondSectionImage" alt="" style="height: 40px;" />
                            </div>
                            <div class="feature-box-content">
                                <span class="d-inline-block fs-18 text-dark-gray fw-700 mb-2">{{ titleSection }}</span>
                                <p class="w-80 mx-auto">{{ secondSectionContent }}</p>
                            </div>
                        </div>
                    </div>
                    <!-- end features box item -->
                    <!-- start features box item -->
                    <div class="col icon-with-text-style-01 md-mb-30px">
                        <div class="feature-box feature-box-center last-paragraph-no-margin text-center">
                            <div class="feature-box-icon mb-3">
                                <img :src="missionImage" alt="" style="height: 40px;" />
                            </div>
                            <div class="feature-box-content">
                                <span class="d-inline-block fs-18 text-dark-gray fw-700 mb-2">{{ titleMission }}</span>
                                <p class="w-80 mx-auto">{{ missionContent }}</p>
                            </div>
                        </div>
                    </div>
                    <!-- end features box item -->
                </div>
            </div>
        </section>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const titleSection = ref('Loading...');
const secondSectionContent = ref('Loading...');
const secondSectionImage = ref('');

const titleMission = ref('Loading...');
const missionContent = ref('Loading...');
const missionImage = ref('');

const fetchData = async () => {
    const page = 'HomePage';
    const sectionID = '665849501b769ba8c36b958d';

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}/sections/${sectionID}`);
        const section = response.data;
        titleSection.value = section.title;

        if (section && section.images && section.images.length > 0) {
            secondSectionImage.value = section.images[0].url;
        }
        secondSectionContent.value = section.content;
    } catch (error) {
        console.error(error);
        secondSectionImage.value = 'default_image_url'; // replace with your default image url
        secondSectionContent.value = 'Error loading content';
    }
};

const fetchDataMission = async () => {
    const page = 'HomePage';
    const sectionID = '665849501b769ba8c36b958f';

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}/sections/${sectionID}`);
        const section = response.data;
        titleMission.value = section.title;

        if (section && section.images && section.images.length > 0) {
            missionImage.value = section.images[0].url;
        }
        missionContent.value = section.content;
    } catch (error) {
        console.error(error);
        missionImage.value = 'default_image_url';
        missionContent.value = 'Error loading content';
    }
};

onMounted(() => {
    fetchData();
    fetchDataMission();
});
</script>

<style>
.feature-box-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.feature-box-icon {
    margin-bottom: 1rem;
}

.feature-box-content span {
    margin-bottom: 0.5rem;
}
</style>

<template>
    <div>
        <section class="ipad-top-space-margin md-pt-0">
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="col-12 col-xl-6 col-lg-8 text-center position-relative page-title-double-large">
                        <div class="d-flex flex-column justify-content-center ">
                            <h1 class="text-dark-gray alt-font ls-minus-1px fw-700 mb-20px">Key Features</h1>

                        </div>

                    </div>
                </div>
            </div>
        </section>
        <section class="py-0">
            <div class="container-fluid">
                <div
                    class="row row-cols-1 row-cols-lg-4 row-cols-sm-2 justify-content-center align-items-center border-top border-color-light-medium-gray">
                    <!-- start fancy text box item -->
                    <div class="col fancy-text-box-style-01 border-color-light-medium-gray p-0"
                        v-for="(section, index) in sections" :key="index">
                        <div class="text-box-wrapper align-items-center d-flex">
                            <div class="position-relative text-center w-100">
                                <div class="text-box last-paragraph-no-margin p-20">
                                    <img :src="section.image" alt="" class="mb-15px">
                                    <br>
                                    <span class="text-dark-gray fw-600 fs-22 lg-fs-20">{{ section.title }}</span>
                                </div>
                                <div class="text-box-hover p-16 lg-p-12 md-p-17 sm-p-12 xs-p-17">
                                    <p class="mb-15px" v-html="section.content.split('•').join('<br>•')"> </p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end fancy text box item -->

                </div>
            </div>
        </section>


        <section class="ipad-top-space-margin md-pt-0">
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="col-12 col-xl-6 col-lg-8 text-center position-relative page-title-double-large">
                        <div class="d-flex flex-column justify-content-center ">
                            <h1 class="text-dark-gray alt-font ls-minus-1px fw-700 mb-20px">Benfits</h1>

                        </div>

                    </div>
                </div>
            </div>
        </section>
        <section class="py-0">
            <div class="container-fluid">
                <div
                    class="row row-cols-1 row-cols-lg-4 row-cols-sm-2 justify-content-center align-items-center border-top border-color-light-medium-gray">
                    <!-- start fancy text box item -->
                    <div class="col fancy-text-box-style-01 border-color-light-medium-gray p-0"
                        v-for="(section, index) in sectionsBenfits" :key="index">
                        <div class="text-box-wrapper align-items-center d-flex">
                            <div class="position-relative text-center w-100">
                                <div class="text-box last-paragraph-no-margin p-20">
                                    <img :src="section.image" alt="" class="mb-15px"
                                        style="filter: hue-rotate(0deg) saturate(100%) contrast(100%) brightness(100%);">
                                    <br>
                                    <span class="text-dark-gray fw-600 fs-22 lg-fs-20">{{ section.title }}</span>
                                </div>
                                <div class="text-box-hover p-16 lg-p-12 md-p-17 sm-p-12 xs-p-17">
                                    <p class="mb-15px" v-html="section.content.split('•').join('<br>•')"> </p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end fancy text box item -->

                </div>
            </div>
        </section>


        <section class="overflow-hidden">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-11 offset-lg-0 offset-md-1 position-relative md-mb-20px">
                        <figure class="position-relative m-0 text-center">
                            <img :src="secondSectionImageExp" alt="">
                            <figcaption class="position-absolute bottom-80px left-0px"
                                data-anime='{ "translateY": [-50, 0], "opacity": [0,1], "duration": 800, "delay": 1000, "staggervalue": 300, "easing": "easeOutQuad" }'>
                                <img :src="secondSectionImageExp" class="animation-float" alt="">
                            </figcaption>
                        </figure>
                    </div>
                    <div class="col-xl-5 offset-xl-1 col-lg-6">
                        <h3 class="fw-700 text-dark-gray ls-minus-1px">{{ titleSectionExp }}</h3>
                        <div class="row row-cols-1 row-cols-lg-1 row-cols-md-2 justify-content-center mb-40px md-mb-0">
                            <!-- start features box item -->
                            <div class="col icon-with-text-style-08 mb-30px">
                                <div class="feature-box feature-box-left-icon overflow-hidden">
                                    <div class="feature-box-content last-paragraph-no-margin">
                                        <span class="d-inline-block fs-18  text-dark-gray mb-5px ls-minus-05px">{{
                                            secondSectionContentExp }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="overflow-hidden">
            <div class="container">
                <div class="row align-items-center">

                    <div class="col-xl-5 offset-xl-1 col-lg-6">
                        <h3 class="fw-700 text-dark-gray ls-minus-1px">{{ titleSectionGet }}</h3>
                        <div class="row row-cols-1 row-cols-lg-1 row-cols-md-2 justify-content-center mb-40px md-mb-0">
                            <!-- start features box item -->
                            <div class="col icon-with-text-style-08 mb-30px">
                                <div class="feature-box feature-box-left-icon overflow-hidden">
                                    <div class="feature-box-content last-paragraph-no-margin">
                                        <span class="d-inline-block fs-18  text-dark-gray mb-5px ls-minus-05px">{{
                                            secondSectionContentGet }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-11 offset-lg-0 offset-md-1 position-relative md-mb-20px">
                        <figure class="position-relative m-0 text-center">
                            <img :src="secondSectionImageGet" alt="">
                            <figcaption class="position-absolute bottom-80px left-0px"
                                data-anime='{ "translateY": [-50, 0], "opacity": [0,1], "duration": 800, "delay": 1000, "staggervalue": 300, "easing": "easeOutQuad" }'>
                                <img :src="secondSectionImageGet" class="animation-float" alt="">
                            </figcaption>
                        </figure>
                    </div>
                </div>
            </div>
        </section>

    </div>

</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const firstSectionImages = ref([]);
const sections = ref([]);
const titles = ref([]);
const serviceSections = ref([]);
const serviceImages = ref([]);

const sectionsBenfits = ref([]);
const titlesBenfits = ref([]);
const serviceSectionsBenfits = ref([]);
const serviceImagesBenfits = ref([]);

const titleSectionExp = ref('Loading...');
const secondSectionContentExp = ref('Loading...');
const secondSectionImageExp = ref('');

const titleSectionGet = ref('Loading...');
const secondSectionContentGet = ref('Loading...');
const secondSectionImageGet = ref('');



const getSliderData = async () => {
    const page = 'AppsPage';
    const sectionID = '665eff8832b3fc659b911f9c';
    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}/sections/${sectionID}`);
        const section = response.data;
        if (section && section.images && section.images.length > 0) {
            firstSectionImages.value = section.images.map(image => image.url);
        }
    } catch (error) {
        console.error('Failed to fetch images:', error);
        firstSectionImages.value = ['default_image_url'];
    }
};

const fetchData = async () => {
    const page = 'AppsPage';
    const sectionIDs = [
        "665f1226ac1f1712687098e9",
        "665f123aac1f1712687098f1",
        "665f124bac1f1712687098fb",
        "665f127aac1f171268709910",
        "665f128cac1f17126870991e",

    ];

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}`);
        const pageData = response.data;
        const filteredSections = pageData.sections.filter(section => sectionIDs.includes(section._id));

        sections.value = filteredSections.map(section => ({
            title: section.title || 'Default Title',
            content: section.content || 'Default content.',
            image: (section.images && section.images.length > 0) ? section.images[0].url : 'content/surveyor/images/default_image.jpg'
        }));

        titles.value = filteredSections.map(section => section.title);
        serviceSections.value = filteredSections.map(section => section.content);
        serviceImages.value = filteredSections.map(section => section.images[0].url);

    } catch (error) {
        console.error('Error fetching data:', error);
        sections.value = [{
            title: 'Error',
            content: 'Error loading content.',
            image: 'content/surveyor/images/default_image.jpg'
        }];
    }
};

const fetchDataofBenefits = async () => {
    const page = 'AppsPage';
    const sectionIDs = [

        "665f12adac1f17126870992e",
        "665f12c6ac1f171268709940",
        "665f12d4ac1f171268709954",
        "665f12e4ac1f17126870996a"
    ];

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}`);
        const pageData = response.data;
        const filteredSections = pageData.sections.filter(section => sectionIDs.includes(section._id));

        sectionsBenfits.value = filteredSections.map(section => ({
            title: section.title || 'Default Title',
            content: section.content || 'Default content.',
            image: (section.images && section.images.length > 0) ? section.images[0].url : ''
        }));

        titlesBenfits.value = filteredSections.map(section => section.title);
        serviceSectionsBenfits.value = filteredSections.map(section => section.content);
        serviceImagesBenfits.value = filteredSections.map(section => section.images[0].url);

    } catch (error) {
        console.error('Error fetching data:', error);
        sectionsBenfits.value = [{
            title: 'Error',
            content: 'Error loading content.',
            image: ''
        }];
    }
};

const fetchDataExp = async () => {
    const page = 'AppsPage';
    const sectionID = '665f39e5de93dd002a38d895';

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}/sections/${sectionID}`);
        const section = response.data;
        titleSectionExp.value = section.title;

        if (section && section.images && section.images.length > 0) {
            secondSectionImageExp.value = section.images[0].url;
        }
        secondSectionContentExp.value = section.content;
    } catch (error) {
        console.error(error);
        secondSectionImageExp.value = 'default_image_url'; // replace with your default image url
        secondSectionContentExp.value = 'Error loading content';
    }
};


const fetchDataGetStarted = async () => {
    const page = 'AppsPage';
    const sectionID = '665f39f1de93dd002a38d8b3';

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}/sections/${sectionID}`);
        const section = response.data;
        titleSectionGet.value = section.title;

        if (section && section.images && section.images.length > 0) {
            secondSectionImageGet.value = section.images[0].url;
        }
        secondSectionContentGet.value = section.content;
    } catch (error) {
        console.error(error);
        secondSectionImageGet.value = 'default_image_url'; // replace with your default image url
        secondSectionContentGet.value = 'Error loading content';
    }
};


onMounted(() => {
    getSliderData();
    fetchData();
    fetchDataofBenefits();
    fetchDataExp();
    fetchDataGetStarted();
});
</script>



<style scoped></style>
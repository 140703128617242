<template>
    <div>
        <section>
            <div class="container">
                <div class="row justify-content-center mb-3">
                    <div class="col-lg-7 col-md-8 col-sm-9 text-center">
                        <h3 class="text-dark-gray fw-700 ls-minus-2px">{{ main_title }}</h3>
                        <span class="d-block text-dark-gray fw-500 fs-16"> {{ serviceSections[0] }}</span>
                    </div>
                </div>
                <div class="row row-cols-1 row-cols-lg-3 row-cols-md-2 justify-content-center mb-5">
                    <!-- start features box item -->
                    <div class="col icon-with-text-style-07 transition-inner-all md-mb-30px mb-3 box-shadow"
                        v-for="(section, index) in sections.slice(1)" :key="index">
                        <div
                            class="bg-very-light-gray h-100 justify-content-end feature-box flex-column-reverse p-15 md-p-13 border-radius-8px">

                            <div class="feature-box-content">
                                <a href="#" class="d-inline-block fw-600 text-dark-gray mb-5px fs-20 ls-minus-05px">{{
                                    section.title }}</a>
                                <div v-for="(item, index) in section.content.split('•')" :key="index">
                                    <p class="mb-30px">{{ item }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end features box item -->

                </div>

            </div>
        </section>
    </div>

</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const sections = ref([]);
let main_title = ref('Loading...');
let titles = ref([]);
let serviceSections = ref([]);
let serviceImages = ref([]);


const fetchData = async () => {
    const page = 'WhyUsPage';

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}`);
        const pageData = response.data;

        if (!pageData || !pageData.sections) {
            throw new Error('Invalid response data');
        }

        const filteredSections = pageData.sections;
        sections.value = filteredSections.map(section => ({
            title: section.title || 'Default Title',
            content: section.content || 'Default content.',
            image: (section.images && section.images.length > 0) ? section.images[0].url : 'content/surveyor/images/default_image.jpg'
        }));

        console.log('Data fetched for sections:', sections.value);
        titles.value = sections.value.map(section => section.title);
        serviceSections.value = sections.value.map(section => section.content);
        serviceImages.value = sections.value.map(section => section.image);

        main_title.value = sections.value[0]?.title || 'Default Title';

    } catch (error) {
        console.error('Error fetching data:', error);
        sections.value = [{
            title: 'Error',
            content: 'Error loading content.',
            image: 'content/surveyor/images/default_image.jpg'
        }];
    }
};

onMounted(fetchData);
</script>



<style scoped></style>
<template>
    <div>
        <Header />
        <Banner />
        <about />
        <principle />
        <navigate />
        <services />
        <clients />
        <WhyUs />
        <finalPart />

    </div>
</template>

<script>

import Header from '../components/layouts/Header.vue'
import Banner from '../components/home/BannerComponent.vue'
import About from '../components/home/AboutComponent.vue'
import principle from '../components/home/PrincipleComponent.vue'
import navigate from '../components/home/NavigateComponent.vue'
import services from '../components/home/ServicesComponent.vue'
import clients from '../components/home/ClientsComponent.vue'
import WhyUs from '../components/home/WhyUsComponent.vue'
import finalPart from '../components/home/FinalPartComponent.vue'

export default {
    name: 'HomePage',
    components: {
        Header,
        Banner,
        About,
        principle,
        navigate,
        services,
        clients,
        WhyUs,
        finalPart


    },
}
</script>

<style scoped></style>
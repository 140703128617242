<template>
    <div>
        <section id="down-section">
            <div class="container">
                <div class="row row-cols-1 row-cols-md-3 row-cols-sm-2 justify-content-center">
                    <!-- start features box item -->
                    <div class="col icon-with-text-style-04 sm-mb-40px">
                        <div class="feature-box last-paragraph-no-margin">
                            <div class="feature-box-icon">
                                <i class="line-icon-Geo2-Love icon-extra-large text-base-color mb-25px"></i>
                            </div>
                            <div class="feature-box-content last-paragraph-no-margin">
                                <span class="d-inline-block alt-font fw-600 text-dark-gray mb-5px fs-20">{{ sections &&
                                    sections[2] ? sections[2].title : 'Loading' }}</span>
                                <p>{{ sections && sections[2] ? sections[2].content : 'Loading' }}</p>
                            </div>
                        </div>
                    </div>
                    <!-- end features box item -->
                    <!-- start features box item -->
                    <div class="col icon-with-text-style-04 sm-mb-40px">
                        <div class="feature-box last-paragraph-no-margin">
                            <div class="feature-box-icon">
                                <i class="line-icon-Headset icon-extra-large text-base-color mb-25px"></i>
                            </div>
                            <div class="feature-box-content last-paragraph-no-margin">
                                <span class="d-inline-block alt-font fw-600 text-dark-gray mb-5px fs-20">Call us
                                    directly</span>
                                <div class="w-100 d-block">
                                    <span class="d-block">
                                        {{ sections && sections[3] ? sections[3].content.replace(/\./g, '.<br>') :
                                            'Loading' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <!-- end section -->
        <!-- start section -->
        <section class="p-0" id="location">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-12 p-0">
                        <div id="map" class="map">
                            <iframe :src="sections && sections[1] ? getIframeSrc(sections[1].content) : ''" width="100%"
                                height="450" style="border:0;" allowfullscreen="" loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- end section -->
        <!-- start section -->
        <section class="bg-very-light-gray">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-7 text-center mb-2">
                        <span
                            class="fw-600 ls-1px fs-16 alt-font d-inline-block text-uppercase mb-5px text-base-color">Feel
                            free to get in touch!</span>
                        <h2 class="alt-font text-dark-gray fw-600 ls-minus-2px">How we can help you?</h2>
                    </div>
                </div>
                <div class="row row-cols-md-1 justify-content-center">
                    <div class="col-xl-9 col-lg-11">
                        <!-- start contact form -->
                        <form id="contactform" @submit.prevent="submitForm" class="row contact-form-style-02">
                            <div class="col-md-6 mb-30px">
                                <input v-model="formData.name"
                                    class="box-shadow-quadruple-large input-name form-control required" type="text"
                                    name="name" placeholder="Your name*" />
                            </div>
                            <div class="col-md-6 mb-30px">
                                <input v-model="formData.email" class="box-shadow-quadruple-large form-control required"
                                    type="email" name="email" placeholder="Your email address*" />
                            </div>
                            <div class="col-md-6 mb-30px">
                                <input v-model="formData.subject" class="box-shadow-quadruple-large form-control"
                                    type="text" name="subject" placeholder="Your subject" />
                            </div>

                            <div class="col-md-12 mb-30px">
                                <textarea v-model="formData.message" class="box-shadow-quadruple-large form-control"
                                    cols="40" rows="4" name="comment" placeholder="Your message"></textarea>
                            </div>
                            <div class="col-md-5 text-center text-md-end sm-mt-20px">
                                <input type="hidden" name="redirect" value="">
                                <button class="btn btn-medium btn-dark-gray btn-box-shadow btn-round-edge submit"
                                    type="submit">send message</button>
                            </div>
                            <div class="col-12">
                                <div class="form-results mt-20px d-none"></div>
                            </div>
                        </form>
                        <!-- end contact form -->
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>


<script setup>
import { ref, onMounted, } from 'vue';
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.css'
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';

const toast = useToast();

const sections = ref([]);
const formData = ref({
    name: '',
    email: '',
    subject: '',
    message: ''
});

const getIframeSrc = (content) => {
    const match = content.match(/src="([^"]*)"/i);
    return match && match[1] ? match[1] : 'No src found';
};
// const filteredLinks = computed(() => {
//     return sections.value ? sections.value.slice(5).filter(section => section.content) : [];
// });
// const getIconClass = (link) => {
//     let iconClass;
//     if (link.includes('github.com')) {
//         iconClass = 'fa fa-github';
//     } else if (link.includes('linkedin.com')) {
//         iconClass = 'fa fa-linkedin';
//     } else if (link.includes('twitter.com')) {
//         iconClass = 'fa fa-twitter';
//     } else if (link.includes('facebook.com')) {
//         iconClass = 'fa fa-facebook';
//     } else if (link.includes('instagram.com')) {
//         iconClass = 'fa fa-instagram';
//     } else {
//         iconClass = 'fa fa-link';
//     }
//     console.log(`Link: ${link}, Icon class: ${iconClass}`);
//     return iconClass;
// };

const fetchData = async () => {
    const page = 'ContactUsPage';

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}`);
        const pageData = response.data;

        sections.value = pageData.sections;

    } catch (error) {
        console.error('Error fetching data:', error);
        sections.value = [{
            title: 'Error',
            content: 'Error loading content.',
            image: 'content/surveyor/images/default_image.jpg'
        }];
    }
};

const submitForm = async () => {
    try {
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/contact/add`, formData.value);
        console.log(response.data);
        // Clear form
        formData.value = {
            name: '',
            email: '',
            subject: '',
            message: ''
        };
        // Show success toast
        toast.success('Form submitted successfully!');
    } catch (error) {
        // Handle error
        console.error(error);
        toast.error('An error occurred while submitting the form.');
    }
};

onMounted(fetchData);
</script>

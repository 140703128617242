<template>
    <div>
        <section class="bg-extra-medium-slate-blue big-section overflow-hidden position-relative">

            <div class="container">
                <div class="row justify-content-center text-base-color mb-3">

                </div>
                <div class="row row-cols-1 row-cols-lg-3 row-cols-md-2 justify-content-center">
                    <!-- start testimonials item -->
                    <div class="col text-center testimonials-style-02 md-mb-30px pb-5"
                        v-for="(section, index) in sections" :key="index">
                        <div class="h-100 position-relative bg-solitude-blue p-15 border-radius-8px">
                            <img :src="section.image" class="d-block mx-auto rounded-circle mb-25px" alt="" />
                            <p class="mb-15px">{{ section.title }}</p>
                            <span class=" text-dark-gray fs-18"> {{ section.content }}</span>
                            <!-- <div class=" text-uppercase fs-13 fw-500 alt-font lh-24">Co founder</div> -->
                        </div>
                    </div>
                    <!-- end testimonials item -->

                </div>
            </div>
        </section>
    </div>

</template>



<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const sections = ref([]);
let main_title = ref('Loading...');
let titles = ref([]);
let serviceSections = ref([]);
let serviceImages = ref([]);


const fetchData = async () => {
    const page = 'ClientPage';

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}`);
        const pageData = response.data;

        if (!pageData || !pageData.sections) {
            throw new Error('Invalid response data');
        }

        const filteredSections = pageData.sections;
        sections.value = filteredSections.map(section => ({
            title: section.title || 'Default Title',
            content: section.content || 'Default content.',
            image: (section.images && section.images.length > 0) ? section.images[0].url : 'content/surveyor/images/default_image.jpg'
        }));

        console.log('Data fetched for sections:', sections.value);
        titles.value = sections.value.map(section => section.title);
        serviceSections.value = sections.value.map(section => section.content);
        serviceImages.value = sections.value.map(section => section.image);

        main_title.value = sections.value[0]?.title || 'Default Title';

    } catch (error) {
        console.error('Error fetching data:', error);
        sections.value = [{
            title: 'Error',
            content: 'Error loading content.',
            image: 'content/surveyor/images/default_image.jpg'
        }];
    }
};

onMounted(fetchData);
</script>



<style scoped></style>
<template>
    <div>
        <section class="bg-very-light-gray pt-2 bg-opacity">
            <div class="container">
                <div class="row justify-content-center mb-3">
                    <div class="col-xl-8 col-lg-9 col-md-10 text-center">
                        <h3 class="fw-700 ls-minus-1px header-text" style="color: #ffff;">
                            {{ titleSection }}</h3>
                        <span class="d-block fs-17 text-white fw-500 mb-5px header-text">{{
                            secondSectionContent }}</span>
                    </div>
                </div>
                <div class="row row-cols-1 row-cols-lg-3 row-cols-sm-2 justify-content-center">
                    <div class="col icon-with-text-style-04 transition-inner-all md-mb-30px pb-3"
                        v-for="(section, index) in sectionsData" :key="index" @click="toggleContent(index)">
                        <div
                            class="feature-box  transition dark-hover bg-white border-radius-6px pt-17 pb-17 ps-15 pe-15 lg-ps-8 lg-pe-8 last-paragraph-no-margin box-shadow-quadruple-large box-shadow-hover overflow-hidden">
                            <div class="feature-box-icon">
                                <img :src="section.image" alt="" style="height: 40px;">
                            </div>
                            <div class="feature-box-content">
                                <span class="d-inline-block text-dark-gray fw-600 fs-17 mb-5px">{{ section.title
                                    }}</span>
                                <p class="text-light-opacity" v-if="section.showContent">{{ section.content }}</p>
                                <p class="text-light-opacity" v-else>
                                    {{ section.content.split(' ').slice(0, 10).join(' ') }}...</p>
                            </div>
                            <div class="feature-box-overlay bg-base-color"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const titleSection = ref('Loading...');
const secondSectionContent = ref('Loading...');
const secondSectionImage = ref('');
const sectionsData = ref([]);

const fetchData = async () => {
    const page = 'HomePage';
    const sectionID = '665849501b769ba8c36b9591';

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}/sections/${sectionID}`);
        const section = response.data;
        titleSection.value = section.title;

        if (section && section.images && section.images.length > 0) {
            secondSectionImage.value = section.images[0].url;
        }
        secondSectionContent.value = section.content;
    } catch (error) {
        console.error(error);
        secondSectionImage.value = 'default_image_url'; // replace with your default image url
        secondSectionContent.value = 'Error loading content';
    }
};

const fetchMultipleSectionsData = async (sectionIDs) => {
    const page = 'HomePage';
    try {
        const promises = sectionIDs.map(sectionID =>
            axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}/sections/${sectionID}`)
        );
        const responses = await Promise.all(promises);
        sectionsData.value = responses.map(response => {
            const section = response.data;
            if (section && section.images && section.images.length > 0) {
                return {
                    title: section.title,
                    image: section.images[0].url,
                    content: section.content,
                    showContent: false
                };
            } else {
                return {
                    title: 'No title available',
                    image: '',
                    content: 'No content available',
                    showContent: false
                };
            }
        });
    } catch (error) {
        console.error('Error fetching data:', error);
        sectionsData.value = [{ title: 'Error', content: 'Error loading content', showContent: false }];
    }
};

const toggleContent = (index) => {
    sectionsData.value[index].showContent = !sectionsData.value[index].showContent;
};

onMounted(() => {
    fetchData();
    fetchMultipleSectionsData(['665849501b769ba8c36b9593', '665849501b769ba8c36b9595', '665849501b769ba8c36b9597', '665849501b769ba8c36b9599', '665849501b769ba8c36b959b']);
});
</script>


<style>
.bg-opacity {
    position: relative;
    background-image: url('https://img.freepik.com/free-vector/gradient-grainy-gradient-background_23-2149911306.jpg?w=1380&t=st=1717839856~exp=1717840456~hmac=c7f27518cca5f9f17c5e83f50c58e686b5d45f3b9cf4a72e5130d8257f8eacbd');
    background-size: cover;
    background-repeat: no-repeat;
}



.bg-opacity::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(209, 21, 21, 0.5);
    /* Change the color and opacity as needed */
}

.header-text {
    position: relative;
}
</style>
<template>
  <div class="container">
    <div class="row justify-content-center mb-5 sm-mb-30px">
      <div class="col-xl-6 col-lg-8 col-md-10 text-center">
          <h3 class="text-dark mb-0 fw-600"></h3>
      </div>
  </div>    <swiper
      :loop="true"
      :autoplay="{
        delay: 1500,
        disableOnInteraction: false
      }"
      :breakpoints="breakpoints"
      :space-between="40"
    >
      <swiper-slide v-for="(logo, index) in serviceImages" :key="index" class="slide">
        <img :src="logo" class="logo" />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.css';

// Install Swiper modules
SwiperCore.use([Autoplay]);

const serviceImages = ref([]);

const fetchData = async () => {
  try {
    const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/ClientsPage`);
    const pageData = response.data;
    if (!pageData || !pageData.sections) {
      throw new Error('Invalid response data');
    }
    serviceImages.value = pageData.sections.flatMap(section =>
      section.images.map(image => image.url || 'content/surveyor/images/default_image.jpg')
    );
  } catch (error) {
    console.error('Error fetching data:', error);
    serviceImages.value = ['content/surveyor/images/default_image.jpg'];
  }
};

onMounted(fetchData);

const breakpoints = {
  // When window width is >= 768px
  768: {
    slidesPerView: 6,
  },
  // When window width is >= 520px
  520: {
    slidesPerView: 4,
  },
  // When window width is < 520px
  0: {
    slidesPerView: 3,
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
  padding: 20px;
}

.slide img {
  width: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 100px; /* Set a fixed width */
  height: 100px; /* Set a fixed height */
  object-fit: contain; /* Ensure the image fits within the specified dimensions */
}
</style>
